import React, { useState } from 'react';

import Items from '../items';
import UpsertApp from './upsert';
import ShowApp from './show';

import api from '../../../services/api';

function AdminApp({ app }) {
    const [edit, setEdit] = useState(!!app.new);
    const [deleted, setDeleted] = useState(false);

    function cancel() {
        if (app.new) {
            setDeleted(true);
        }
        setEdit(false);
    }

    function setAlpha(value) {
        app.alpha = value;
        updateApp();
    }

    async function save() {
        try {
            const saveFunction = app.new ? createApp : updateApp;
            await saveFunction();
            setEdit(false);
        }
        catch {}
    }

    async function createApp() {
        const result = await api.post('/v0/apps', app);
        app._id = result.data.application._id;
        delete app.new;
    }

    async function deleteApp() {
        try {
            await api.delete(`/v0/apps/${app._id}`);
            setDeleted(true);
        }
        catch {}
    }

    async function updateApp() {
        await api.put(`/v0/apps/${app._id}`, app);
    }

    return !deleted && (
        <div className="app-content">
            {edit
                ? <UpsertApp
                    app={app}
                    cancel={cancel}
                    save={save}
                />
                : <ShowApp
                    app={app}
                    edit={() => setEdit(true)}
                    deleteApp={deleteApp}
                    setAlpha={setAlpha}
                />
            }
            {!app.new && <Items appId={app._id} resource="bugs" />}
            {!app.new && <Items appId={app._id} resource="suggestions" />}
        </div>
    );
}

export default AdminApp;
