import React, { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import Header from '../../components/header';
import AdminApp from '../../components/admin/app/index';

import api from '../../services/api';

function AdminDashboard() {
    const [apps, setApps] = useState([]);
    let counter = 0;

    useEffect(() => {
        (async function() {
            const result = await api.get('/v0/apps');
            setApps(result.data.applications);
        })();
    }, []);

    function addApp() {
        setApps([
            ...apps,
            { new: true},
        ]);
    }

    return (
        <>
            <Header />
            {apps && apps.map(app => (
                <AdminApp key={app._id || counter++} app={app} />
            ))}
            <div className="admin-new-app-box">
                <button
                    className="icon-link"
                    title="New App"
                    onClick={addApp}
                >
                    <FiPlus />
                </button>
            </div>
        </>
    );
}

export default AdminDashboard;
